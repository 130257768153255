module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vulcain","short_name":"Sharecare","description":"Vulcain | Sharecare","lang":"en","start_url":"/","background_color":"#FFF","theme_color":"#00BFA5","display":"standalone","icon":"src/assets/logos/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"acd3772dc51de99fa123cfd895c82921"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://vulcain.compliance.sharecare.fr","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00BFA5","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/ci/ws/amba-landing-pages/src/app/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["a4a7f3aa-284f-4e1c-a546-2b2264c737bc"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":3600},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"containerUrl":"https://sharecare-europe.piwik.pro","siteId":["56e7bbe4-d952-4260-b0b6-f82ba62be074"],"enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
