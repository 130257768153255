// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-app-views-static-vulcain-pages-cgu-jsx": () => import("./../../../src/app/views/static/vulcain/pages/CGU.jsx" /* webpackChunkName: "component---src-app-views-static-vulcain-pages-cgu-jsx" */),
  "component---src-app-views-static-vulcain-pages-home-communication-jsx": () => import("./../../../src/app/views/static/vulcain/pages/HomeCommunication.jsx" /* webpackChunkName: "component---src-app-views-static-vulcain-pages-home-communication-jsx" */),
  "component---src-app-views-static-vulcain-pages-legals-jsx": () => import("./../../../src/app/views/static/vulcain/pages/Legals.jsx" /* webpackChunkName: "component---src-app-views-static-vulcain-pages-legals-jsx" */),
  "component---src-app-views-static-vulcain-pages-rgpd-jsx": () => import("./../../../src/app/views/static/vulcain/pages/RGPD.jsx" /* webpackChunkName: "component---src-app-views-static-vulcain-pages-rgpd-jsx" */),
  "component---src-app-views-static-vulcain-pages-user-guide-jsx": () => import("./../../../src/app/views/static/vulcain/pages/UserGuide.jsx" /* webpackChunkName: "component---src-app-views-static-vulcain-pages-user-guide-jsx" */)
}

